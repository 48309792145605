<template>
    <div class="container">
        <div class="m-wrapper09">
            <div class="m-hospital-info01">
                <div class="hospital-info-con01">
                    <h5 class="hospital-name02">{{hospitalInfo.hosName}}</h5>
                    <span class="hospital-tag01">{{hospitalInfo.levelName}}</span>
                </div>
                <p class="address01">{{hospitalInfo.address}}</p>
            </div>
            <ul class="m-tab05">
                <li v-for="item in departmentList" :key="item.name" @click="selectDoctor(item)">
                    <a>{{item.deptName}}</a>
                </li>
            </ul>
        </div>

        <!-- 提示弹窗 -->
        <div v-show="isShowNotice">
            <div class="weui-mask weui-mask--visible"></div>
            <div class="weui-custom-pop weui-dialog--visible">
                <div class="pop-tit01">
                    {{hospitalInfo.hosName}}
                    <a href="javascript:;" class="close-btn01" onclick="closeNoticePop()"></a>
                </div>
                <div class="agree-con01">
                    <div class="agree-box01">
                        <p>温馨提示：</p>
                        <p>{{hospitalInfo.message}}</p>
                        <p style="text-align: right;">{{hospitalInfo.hosName}}</p>
                    </div>
                    <div class="statement">
                        <img src="images/stat.png" class="icons-state">声明：该内容由医院方提供，本平台仅提供发布服务。
                    </div>
                </div>
                <a href="javascript:;" class="agree-btn01" @click="closeNoticePop">我知道了</a>
            </div>
        </div>
    </div>
</template>
<script>
import {mapState} from 'vuex'
export default {
    data() {
        return {
            isShowNotice: true
        }
    },
    computed: {
        ...mapState({
            hospitalInfo: state => state.layout.hospitalInfo,
            departmentList: state => state.selectDepartment.departmentList
        })
    },
    mounted() {
        // 发请求获取医院的相关信息
        this.$store.dispatch('getHospitalInfo', {
            hospitalId: this.$route.query['id']
        })
        // 获取科室信息
        this.$store.dispatch('getDepartmentList').then(() => {
            console.log('info,', this.departmentList)
        })
    },
    methods: {
        closeNoticePop() {
            this.isShowNotice = false
        },
        selectDoctor(item) {
            // 选择了哪个科室
            console.log('选择了哪个科室,', item)
            // &hosName=${this.hospitalInfo.hosName}
            this.$router.push(`/selectDoctor?hosDeptId=${item.hosDeptId}&depName=${item.deptName}&hospitalId=${this.$route.query['id']}`)
        }
    }
}
</script>
<style lang="scss" scoped>
$mainColor: #00C2C3;
.container {
    text-align: left;
    .agree-btn01 {
        color: $mainColor;
    }

    .hospital-tag01 {
        background: $mainColor;
        color: #fff;
    }
}
</style>